export const useSearchPanelStore = defineStore('searchPanelStore', () => {
  const inputValue = ref('');
  const searchPanel = ref(null);
  const showModal = ref(false);
  const isClickedOnClinic = ref(false);

  const searchPanelIsVisible = useElementVisibility(searchPanel);

  return { inputValue, searchPanel, showModal, isClickedOnClinic, searchPanelIsVisible };
});
