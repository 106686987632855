export const useHeaderStore = defineStore('headerStore', () => {
  const showMobileSearchModal = ref(false);

  const openMobileSearchModal = () => {
    showMobileSearchModal.value = true;
    document.documentElement.style.overflowY = 'hidden';
  };

  const closeMobileSearchModal = () => {
    showMobileSearchModal.value = false;
    document.documentElement.style.overflowY = 'initial';
  };

  return {
    showMobileSearchModal,
    openMobileSearchModal,
    closeMobileSearchModal
  };
});
