<template>
  <div class="clinic-result-wrapper">
    <div class="clinic-result">
      <div class="up">
        <span class="text-main">
          Все клиники
        </span>
      </div>
      <div class="down">
        <span class="text-address">
          Поиск по всем клиникам
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.clinic-result-wrapper {
  width: 100%;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  &:hover {
    background-color: #F7F8FA;
  }
}
.clinic-result {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.up {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.text-main {
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.text-address {
  color: #878FA2;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.048px;
}
</style>
